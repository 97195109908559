import React from "react"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import GetInTouch from "../../components/get-in-touch"
// import mobileAppIconBig from "../../assets/images/mobile-app-service-icon-big.png"
import mobileAppIconBig from "./mobile-app-service.png"
import TS1 from "./images/people.png"
import ReactNative from "../../assets/images/react-native.svg"

import engagementBilling from "./icons/engagement-billing.png"
import done from "./images/done.png"
import CaseStudyList from "../../components/case-study-list"
import Testimonial from "../../components/testimonial"
import RecipeList from "../../components/recipe-list"
import { MDXRenderer } from "gatsby-plugin-mdx"
import link from "../../assets/images/link.png"
import Circle from "./images/circle.png"
import CtaShape from "./images/cta-shape.png"
import Reactjs from "../../assets/images/reactjs.svg"
import Angular from "../../assets/images/angular-logo.webp"
import Gatsby from "../../assets/images/Gatsby-Monogram.svg"
import AppDesignBanner from "./images/banner.png"
import MainDesignBanner from "./images/banner1.png"
import TailoredSolution from "./icons/solution.png"
import CuttingEdge from "./icons/cutting-edge.png"
import UniqueApproach from "./icons/unique-approach.png"
import CustomizedProcess from "./icons/customized-process.png"
import HeroImage from "./icons/heroImg.png"
import Badge1 from "./../../content/awards/design-rush-top-mobile-app-developers/badge.png"
import Badge2 from "./../../content/awards/clutch-top-mobile-app-developers/badge.png"
import GreekyGuy from "./images/greeky.png"

import Loadable from "@loadable/component"
import ScheduleMeetingDialog from "../../components/ScheduleMeetingDialog.js"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))
const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 7000,
  items: 1,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}
const awardsCarouselOptions = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  items: 1,
  margin: 30,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}

const ServiceDetails = ({ data }) => {
  const [display, setDisplay] = React.useState(false)
  // console.log(data)

  const recipeFeatures = data?.ourRecipeFeatures?.nodes
  // console.log("recipeFeatures", recipeFeatures)

  const caseStudyList = data?.caseStudies?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  const [showCalendlyWidget, setShowCalendlyWidget] = React.useState(false)

  const featuredCaseStudy =
    data.featuredCaseStudy.siteMetadata.mobileFeaturedCaseStudies
  const filteredCaseStudyList = featuredCaseStudy.reduce((acc, cur) => {
    const studyListItem = caseStudyList.find(
      item => item.relativeDirectory === cur
    )
    if (studyListItem) {
      acc.push(studyListItem)
    }
    return acc
  }, [])
  console.log("filteredCaseStudyList", filteredCaseStudyList)
  // console.log("caseStudyList", caseStudyList)

  const awards = data?.awards?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("awards", awards)
  const testimonials = data?.testimonials?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("testimonials", testimonials)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  const handleScheduleMeeting = event => {
    event?.preventDefault()
    setShowCalendlyWidget(true)
  }

  return (
    <Layout>
      <SEO
        title="Tarkashilpa - Mobile Application Development Services Company "
        description="We are a leading mobile application development services company. We help businesses to develop effective and innovative applications for their business purpose."
        keywords="mobile app development, mobile app development company, mobile app development services, mobile application development company, best mobile app development company"
        // url={`${window.location.origin}/services/mobile-app-development/`}
        url={`/services/mobile-app-development/`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      {/* <PageBanner pageTitle="Mobile App Development" /> */}

      <div className="pt-5 overflow-x-hidden">
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-md-6 ">
              <div className="services-details-desc text-md-start text-center">
                {/* <h3>Incredible Infrastructure</h3> */}
                <h1 className="pb-4 pt-4 pb-0 mb-0">
                  Unlock your business potential to the Fullest with
                  <i style={{ color: "rgb(105 211 75)" }}> Tarka Shilpa</i>
                </h1>

                <h3
                  style={{
                    color: "#6084a4",
                    paddingBottom: 0,
                    marginBottom: 0,
                    paddingTop: 10,
                  }}
                >
                  Get apps tailored to your specific business needs, unique
                  business objectives & drive digitization to your business with
                  innovation and technology.
                </h3>
                <div className="pt-4 mt-3">
                  <a
                    className="btn btn-primary"
                    onClick={handleScheduleMeeting}
                  >
                    Get in touch
                  </a>
                  <p className="pt-1"> (Not a sleazy sales call) </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <img
                src={HeroImage}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Mobile App Development"
                style={{
                  maxWidth: "unset",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* small banner */}
      <div className="cta-area pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 px-lg-0">
              <p className="pb-4 pt-4 pe-2">
                <h4 className="text-white pb-0 mb-0">
                  We work with you to understand the problem you’re trying to
                  solve and the software solution to that problem.{" "}
                </h4>
              </p>
            </div>

            <div className="col-md-6">
              <div className="cta-right-content">
                <div className="hosting-price">
                  <h1 className="pb-4 pt-4 text-white">
                    From concept to reality Without a
                    <i style={{ color: "#000" }}> Hitch </i>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* small banner ends here */}

      {/* trusted banner part */}
      <div className="services-details-area">
        <div className="container">
          <div className="row align-items-center pt-5">
            <div className="col-lg-6 col-md-6">
              <h1>
                {" "}
                Trusted & Recognized As top App developers’ Multiple times
              </h1>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row justify-content-end align-items-center">
                <div className="col-lg-3 col-6 pe-2 pe-md-4 pt-4 pt-md-0">
                  <img src={Badge1} />
                </div>
                <div className="col-lg-3 col-6 pe-4 pt-4 pt-md-0">
                  <img src={Badge2} />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4">
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-lg-row flex-column  gap-3">
                  <div class="flex-1 single-iot-box">
                    <div className="d-flex align-self-stretch">
                      <div class="">
                        <h4 className="pb-2 pt-0 mb-0 pb-4">
                          Get Dedicated attention for your business
                        </h4>
                        <p className="py-0 mb-0">
                          You’re equipped with a perfect blend of processes,
                          practices, and efficient tools combined with people of
                          the right skill set.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 single-iot-box">
                    <div className="d-flex align-self-stretch ">
                      <div class="">
                        <h4 className="pb-2 pt-0 mb-0 pb-4">
                          Make solid business decisions
                        </h4>
                        <p className="py-0 mb-0">
                          Fast team building, flexible collaboration with proven
                          expertise to supercharge your business & create your
                          path to success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 single-iot-box">
                    <div className="d-flex align-self-stretch">
                      <div class="">
                        <h4 className="pt-0 mb-0 pb-4">
                          Streamline your business work-flows
                        </h4>
                        <p className="py-0 mb-0">
                          Our development process is iterative and built to
                          support any stage of growth your business or business
                          is in.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* trusted banner part ends here */}

      {/* software solution section */}
      <div className="pt-5 ">
        <div className="bg-eef6fd ">
          <div className="services-details-area pb-5 pt-5">
            <div className="container pt-2">
              <div className="section-title">
                <h1 className="">
                  {" "}
                  Get Ahead with the{" "}
                  <i style={{ color: "rgb(105 211 75)" }}> RIGHT </i>
                  software solutions
                </h1>
                <div className="bar"></div>
                <h3 style={{ color: "#6084a4", lineHeight: 1.4 }}>
                  Whether you need a simple app to manage your customer data or
                  a complex solution to optimize your supply chain, we’ve got
                  you covered.
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-features p-4 mb-3">
                    <div className="row">
                      <div style={{ width: 70 }}>
                        <img src={TailoredSolution} width={50} />
                      </div>
                      <div className="flex-1">
                        <h4 className="">Tailored Solutions</h4>
                        <p>
                          Our software development expertise customizes
                          solutions for businesses based on their specific needs
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-features p-4 mb-3">
                    <div className="row">
                      <div
                        style={{
                          width: 70,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={CuttingEdge} width={45} />
                      </div>
                      <div className="flex-1">
                        <h4 className="">Cutting-Edge Services</h4>
                        <p>
                          We offer a comprehensive range of services to help you
                          leverage the full potential of digital technology.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-features p-4 mb-3">
                    <div className="row">
                      <div style={{ width: 70 }}>
                        <img src={UniqueApproach} width={50} />
                      </div>
                      <div className="flex-1">
                        <h4 className="">Unique Approach</h4>
                        <p>
                          Our solutions are highly adaptable to suit any
                          business while enhancing their durability and
                          reliability.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-features p-4 mb-3">
                    <div className="row">
                      <div style={{ width: 70 }}>
                        <img src={CustomizedProcess} width={50} />
                      </div>
                      <div className="flex-1">
                        <h4 className="">Customized Process</h4>
                        <p>
                          Our objective is to offer personalized solutions that
                          aid in the success of your business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 row align-items-center">
                  <img
                    src={engagementBilling}
                    className="animate__animated animate__zoomIn animate__delay-0.2s ps-4"
                    alt="engagementBilling"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          // className="iot-cta-area bg-0f054b"
          style={{ backgroundColor: "#0f054b" }}
        >
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-md-6 px-0 px-lg-5 pt-5 pt-md-0">
                <div className="">
                  <img
                    src={GreekyGuy}
                    className="animate__animated animate__fadeInUp animate__delay-0.6s"
                    alt="image"
                  />
                </div>
              </div>

              <div className="col-md-6 py-3">
                <div className="cta-iot-content">
                  <h2 className="text-white">
                    People{" "}
                    <span style={{ color: "rgb(105 211 75)" }}>“Dress”</span> to
                    impress we design
                    <span style={{ color: "rgb(105 211 75)" }}> “App”</span> for
                    business success
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="services-details-area ">
          <div className="container">
            <div>
              <div className="pt-5">
                <div className="d-flex justify-content-center">
                  <div className="section-title">
                    <h4>With Our Expertise</h4>
                    <h1>
                      It’s Easy to{" "}
                      <span style={{ color: "rgb(105 211 75)" }}>
                        Grow your Business
                      </span>
                    </h1>
                    <div className="bar"></div>
                    <h3 style={{ color: "#6084a4" }}>
                      We forge long term relationship & build digital solutions
                      with the latest technologies that line up with your
                      business goals.
                    </h3>

                    <div className="d-flex flex-md-row flex-column pt-5 gap-3">
                      <div class="flex-1 single-iot-box">
                        <div class="pb-0 mb-0">
                          <h4 className="p-4 pt-0 my-0">
                            Create effervescence with our Web Apps
                          </h4>
                          <div className="text-secondary">
                            <div className="d-flex gap-2">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                Automate your business processes
                              </div>
                            </div>
                            <div className="d-flex gap-2 ">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                Create synergy & Effervescent productivity
                              </div>
                            </div>
                            <div className="d-flex gap-2 ">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                Simplifies tracking of your business KRA and
                                drives powerful insights from the business data.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-1 single-iot-box">
                        <div class="pb-0 mb-0">
                          <h4 className="p-4 my-0 pt-0">
                            Kick-start your growth with Mobile apps
                          </h4>

                          <div className="text-secondary">
                            <div className="d-flex gap-2 ">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                Forge a very intimate connection between your
                                brand and customers
                              </div>
                            </div>
                            <div className="d-flex gap-2 py-1">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                Helps gain clarity and better visibility
                              </div>
                            </div>
                            <div className="d-flex gap-2 py-1">
                              <div style={{ minWidth: 25 }}>
                                <img
                                  src={done}
                                  alt="engagementBilling"
                                  width={25}
                                />
                              </div>
                              <div style={{ textAlign: "start" }}>
                                totally flexible, offering access from almost
                                any device or browser.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-2">
            <div className="bg-eef6fd pt-5 pb-2">
              <div className="container">
                <div className="section-title pb-0">
                  <h1>Get top notch tech Solutions</h1>
                  <h3 style={{ color: "#6084a4" }}>
                    for any challenge you throw at us
                  </h3>
                  <div className="bar"></div>
                </div>
                <div>
                  <div className="row align-items-center pt-0">
                    <div className="col-md-6">
                      <div className="cta-content">
                        <h2 className="text-secondary font-weight-bold">
                          Let our team provide you with a no-cost, no-commitment
                          technical proposal for your next enterprise custom
                          project.
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="justify-content-center pt-3 pt-md-0">
                        <div className="align-items-center">
                          <img
                            src={TS1}
                            className="animate__animated animate__fadeInUp animate__delay-0.6s img-responsive"
                            alt="Mobile App Development"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 pb-5 d-flex justify-content-center">
                    <a
                      className="btn btn-primary"
                      onClick={handleScheduleMeeting}
                    >
                      Cut Time, Schedule a meeting
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="pt-4 d-flex justify-content-center">
                  <div className="col-md-6">
                    <h3 className="text-secondary">
                      Let our team provide you with a no-cost, no-commitment
                      technical proposal for your next enterprise custom
                      project.
                    </h3>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <img
                      src={mobileAppIconBig}
                      className="animate__animated animate__fadeInUp animate__delay-0.6s"
                      alt="Mobile App Development"
                    />
                  </div>
                </div> */}
            <div className="services-details-area bg-0f054b mb-3">
              <div className="container pb-3">
                <div className="pt-4 pb-3 row justify-content-center align-items-center">
                  <div className="col-xl-5 col-md-6 col-xl-offset-1 d-flex justify-content-center">
                    <img src={MainDesignBanner} height={300} />
                  </div>
                  <div className="col-md-6">
                    <div className="p-3 text-md-start text-center">
                      <h2 className="text-white pb-3">
                        We Build Mobile Apps That Connect Deeper and Go Further
                      </h2>
                      <p style={{ color: "rgb(105 211 75)" }}>
                        What sets us apart from other app development agencies:
                        We build high valued apps in iterations such that at the
                        end of each iteration we deliver a demonstrable piece of
                        work to product owners.
                      </p>
                      <div className="pt-3">
                        <a className="btn btn-primary" href="/our-recipe">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* technologies section starts here */}
      <div className="container pt-5 pb-5">
        <div className="section-title">
          <h1 className="text-secondary">What technology do we use?</h1>
          <div className="bar"></div>
          <h3
            style={{
              color: "#6084a4",
            }}
          >
            Our engineers apprehend your business requirements and help you
            choose the right technology for your solution.
          </h3>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-3 py-4">
            <h3 className="text-secondary">Mobile App Development </h3>
          </div>
          <div className="col-lg-9 mb-4">
            <div className="d-flex flex-lg-row flex-column justify-content-center tech-stack-gap">
              <div className="flex-1 single-iot-box">
                <div className="">
                  <div className="icon">
                    <a
                      href="https://reactnative.dev/"
                      target="_blank"
                      rel="nofollow"
                      className="tech-logo"
                    >
                      <img src={ReactNative} alt="image" />
                    </a>
                  </div>
                  <h3>React Native</h3>
                  <p>
                    We build apps on a robust and cross-platform React Native
                    tech stack using one of the Redux or Mobx for the state
                    management. React Native helps us strike the best balance
                    between performance and cost. It gives near-native
                    performance while saving development and maintenance costs
                    by keeping most of the app's code common across the mobile
                    platforms- Android and iOS
                  </p>
                  <Link
                    to="https://reactnative.dev/"
                    target="_blank"
                    rel="nofollow"
                  >
                    <Icon.ArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center mb-4">
          <div className="col-lg-3 pb-4 pt-3">
            <h3 className="text-secondary">Web App Development </h3>
          </div>
          <div className="col-lg-9 d-lg-flex">
            <div className="">
              <div className="d-flex flex-lg-row flex-column justify-content-center tech-stack-gap gap-3">
                <div className="flex-1 single-iot-box">
                  <div className=" ">
                    <div className="icon">
                      <a
                        href="https://reactjs.org"
                        target="_blank"
                        rel="nofollow"
                        className="tech-logo"
                      >
                        <img src={Reactjs} alt="image" />
                      </a>
                    </div>
                    <h3>Reactjs</h3>
                    <p>
                      React is our first choice of technology while building
                      dynamic web apps because of the freedom it provide to
                      architect our apps that suits best to project
                      requirements. We can choose a highly scalable architecture
                      for very large and complex apps or choose a simple
                      architecture for the basic app to keep it easy. We use
                      Redux / Mobx as the state management companion with React.
                    </p>
                    <Link
                      to="https://reactjs.org"
                      target="_blank"
                      rel="nofollow"
                    >
                      <Icon.ArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="flex-1 single-iot-box">
                  <div className="">
                    <div className="icon">
                      <a
                        href="https://angular.io"
                        target="_blank"
                        rel="nofollow"
                        className="tech-logo"
                      >
                        <img src={Angular} alt="image" />
                      </a>
                    </div>
                    <h3>Angular</h3>
                    <p>
                      Angular is a modern and component-based framework that we
                      could use while building dynamic web apps. Angular helps
                      in keeping our architecture consistent and structured and
                      helps us build professional-grade web apps groud up
                      quickly.
                    </p>
                    <Link
                      to="https://angular.io"
                      target="_blank"
                      rel="nofollow"
                    >
                      <Icon.ArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="flex-1 single-iot-box">
                  <div className="">
                    <div className="icon ">
                      <a
                        href="https://www.gatsbyjs.com"
                        target="_blank"
                        rel="nofollow"
                        className="tech-logo"
                      >
                        <img src={Gatsby} alt="image" />
                      </a>
                    </div>
                    <h3>GatsbyJs</h3>
                    <p>
                      Gatsbyjs is our choice of technology while building a
                      content based websites. Gatsby has ready transformers and
                      connectors which could let us connect our website with CMS
                      of our choice. The site build using Gatsby is blazingly
                      fast as most of the content is rendered during build time.
                    </p>
                    <Link
                      to="https://www.gatsbyjs.com"
                      target="_blank"
                      rel="nofollow"
                    >
                      <Icon.ArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* technologies section ends here */}

      <div className="cta-area">
        <div className="service-title p-5">
          <h2 className="text-white d-flex justify-content-center align-items-center text-align-center">
            “We also monitor our competitors to guarantee our rates are always
            among the lowest.”
          </h2>
        </div>
      </div>
      {/* testimonial section */}

      <div className="bg-f9fafb pt-5 pb-50" id="testimonials">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center flex-column section-title">
            <h1 className="text-secondary">Don’t take our word for it</h1>
            <h4
              style={{
                color: "rgb(105 211 75)",
                marginBottom: 2,
              }}
            >
              Hear What Our Clients Say
            </h4>
            <div className="bar"></div>
            <h3
              style={{
                color: "#6084a4",
              }}
            >
              We transform clients’ ideas into reality. Let’s see what made them
              love our work.
            </h3>
          </div>
        </div>

        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {display ? (
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {testimonials &&
                    testimonials.length > 0 &&
                    testimonials.map(list => (
                      <Testimonial data={list?.childMdx?.frontmatter} />
                    ))}
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* testimonials section ends here  */}

      {/* case studies */}
      <div className="case-studies py-5" id="case-studies">
        <div className="container pt-2">
          <div className="section-title">
            <h3
              style={{
                color: "#6084a4",
              }}
            >
              Browse through a mix of our latest clients. It's to the point.
            </h3>
            <h1 className="hover-link">
              This is what you
              <i style={{ color: "rgb(105 211 75)" }}> came for</i> .
            </h1>
            <div className="bar"></div>
          </div>
          <div className="row justify-content-center">
            {filteredCaseStudyList && filteredCaseStudyList.length > 0 && (
              <div className="d-flex flex-md-row flex-column justify-content-center gap-3">
                {filteredCaseStudyList.map((list, index) => (
                  <div className="flex-1 single-solutions-box" key={index + 1}>
                    <CaseStudyList data={list?.childMdx} />
                  </div>
                ))}
              </div>
            )}
            <div className="text-align-center">
              <a className="btn btn-primary" href="/our-work">
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* case studies section ends here */}

      {/* awards section  */}

      <div className="awards-recognition py-5" id="awards-recognition">
        <div className="container">
          <div className="section-title">
            <h1 className="text-secondary">
              <span style={{ color: "rgb(105 211 75)" }}>Curious</span> How we
              do all of this?
            </h1>
            <div className="bar"></div>
            <h3
              style={{
                color: "#6084a4",
              }}
            >
              We are recognized for our expertise to meet your highest
              expectations and build even more innovative solutions
            </h3>
          </div>
          <div className="awards-screenshots">
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...awardsCarouselOptions}
            >
              {awards &&
                awards.length > 0 &&
                awards.map((list, index) => (
                  <div className="awards-wrapper" key={index + 1}>
                    <h3>{list?.childMdx?.frontmatter.title}</h3>
                    <div
                      className={`img-wrapper ${
                        list?.childMdx?.frontmatter?.imageSize === "big"
                          ? "big"
                          : ""
                      }`}
                    >
                      <img
                        src={list?.childMdx?.frontmatter.preview?.publicURL}
                        alt={list?.childMdx?.frontmatter.name}
                      />
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* awards section ends here  */}

      {/* <div className="our-recipe pt-80 pb-50 bg-f9f6f6" id="our-recipe">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#our-recipe">
                  <img src={link} alt="" />
                </a>
                Our Recipe
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              Our recipe to deliver winning apps is the perfect blend of
              processes, practices, and efficient tools combined with people of
              the right skill set. We groom our team with a favorable culture
              that encourages them to understand fundamentals and appreciate the
              value they provide to businesses. Below mentioned are the guiding
              principles of our recipe. Our full recipe is available{" "}
              <a href="/our-recipe">here</a>
            </p>
          </div>
          {recipeFeatures &&
            recipeFeatures.length > 0 &&
            recipeFeatures.map((list, index) => {
              const type = index % 2 === 0 ? "odd" : "even"
              // console.log("type", type)
              return (
                <RecipeList
                  title={list.childMdx.frontmatter.title}
                  content={<MDXRenderer>{list?.childMdx?.body}</MDXRenderer>}
                  thumb={list.childMdx.frontmatter.thumbImg.publicURL}
                  type={type}
                />
              )
            })}
        </div>
        <div className="text-align-center">
          <a className="btn btn-primary" href="/our-recipe">
            Learn More
          </a>
        </div>
      </div> */}

      {/* pricing section starts here*/}
      {/* <div
        className="services-area ptb-80 bg-f7fafd"
        id="engagement-and-billing-model"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2 className="hover-link">
                  <span>
                    <a href="#engagement-and-billing-model">
                      <img src={link} alt="" />
                    </a>
                    Pricing
                  </span>
                </h2>
                <div className="bar"></div>
              </div>
              <p>
                We believe in setting the expectations upfront and keep the
                pricing transparent. We also believe in educating our clients if
                necessary with the pros and cons of different engagement,
                delivery and billing models so they can take a informed
                decision. The choice of right models depends on various factors
                and we love to work with you to help you choose the right one
                based on your needs.
              </p>
              <p>
                Best pricing plan for you depends on if your primarily objective
                is in-budget delivery or high delivering apps. We recommend you
                to keep in-budget delivery as the objective if you are just
                starting the app or have limited time or knowledge to get into
                the nitty gritty of the deliveries. We would make sure we
                deliver in budget while keeping app's ROI to optimum.
              </p>
              <p>
                If you are looking for apps that deliver huge business outcomes
                then we recommend agile delivery model where we build the app by
                trial and iterations. There is reason why trail and iterations
                delivers high performing app, it's impossible to define and
                design the perfect app on paper, best way it to build a good
                enough app and validate, take feedback and improvise in further
                iteration. We cover you from budget standpoint by providing
                ballpark budget and are committed to get the delivery done well
                within this budget.
              </p>
            </div>

            <div className="col-lg-6 col-md-12">
              <img
                src={engagementBilling}
                className="animate__animated animate__zoomIn animate__delay-0.2s"
                alt="engagementBilling"
              />
            </div>
          </div>
        </div>
      </div> */}

      <GetInTouch />

      <Footer />
      {showCalendlyWidget && (
        <ScheduleMeetingDialog
          show={showCalendlyWidget}
          hide={setShowCalendlyWidget}
        />
      )}
    </Layout>
  )
}

export default ServiceDetails

export const query = graphql`
  query mobileAppServices {
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
    testimonials: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        name: { eq: "index" }
        childMdx: {
          slug: {
            in: ["deepak-puthiyedath/", "roshan-radhakrishnan/", "amit-kundra/"]
          }
        }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            client
            reviewerDesignation
            title
            summary
            preview {
              publicURL
            }
            link
          }
        }
        relativeDirectory
        name
      }
    }
    awards: allFile(
      filter: {
        sourceInstanceName: { eq: "awards" }
        name: { eq: "index" }
        childMdx: {
          slug: {
            in: [
              "clutch-top-mobile-app-developers/"
              "design-rush-top-mobile-app-developers/"
              "hindustan-times/"
              "reverbico-top-rn-devs/"
              "the-manifest/"
            ]
          }
        }
      }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            name
            title
            order
            preview {
              publicURL
            }
            imageSize
          }
          slug
        }
        relativeDirectory
        name
      }
    }
    ourRecipeFeatures: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipeFeatures" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            order
          }
          body
        }
      }
    }
    featuredCaseStudy: site {
      siteMetadata {
        mobileFeaturedCaseStudies
      }
    }
  }
`
